
import { Table, TableData, TableHeader, TableRow } from '@/components/table'
import { Config } from 'tradingmate-modules/src/environment'
import { BusinessBranchContactMethod, BusinessBranchModel } from 'tradingmate-modules/src/models/api/businesses'
import ContactMethodType from 'tradingmate-modules/src/models/api/ContactMethodType'
import { Services } from 'tradingmate-modules/src/services'
import Utils from 'tradingmate-modules/src/Utils'
import { Vue, Component } from 'vue-property-decorator'

@Component({
  components: {
    Table,
    TableRow,
    TableData,
    TableHeader
  }
})
export default class MembershipsNew extends Vue {
  public newMembers: BusinessBranchModel[] | null = null
  private loading = false;

  private isoToDisplayDate = Utils.isoToDisplayDate

  mounted (): void {
    this.getExpiredMembers()
  }

  getExpiredMembers (): void {
    this.loading = true
    Services.API.Admin.Businesses.GetNewAndRenewedBranches()
      .then((members) => { this.newMembers = members })
      .finally(() => { this.loading = false })
  }

  getLandline (contactMethods: BusinessBranchContactMethod[]): string {
    return contactMethods.find((method) => method.ContactMethodType === ContactMethodType.Landline)?.Value ?? ''
  }

  getMobile (contactMethods: BusinessBranchContactMethod[]): string {
    return contactMethods.find((method) => method.ContactMethodType === ContactMethodType.MobilePhone)?.Value ?? ''
  }

  getEmail (contactMethods: BusinessBranchContactMethod[]): string {
    return contactMethods.find((method) => method.ContactMethodType === ContactMethodType.Email)?.Value ?? ''
  }

  onViewProfile (slug: string): void {
    var frontendBaseUrl = Config.WebsiteUrls.Public
    var profilePath = `/members/${slug}`
    var url = frontendBaseUrl + profilePath
    window.open(url)
  }

  onEditProfile (id: string): void {
    this.$router.push({ name: 'Business Branch Edit', params: { id: id } })
  }

  onEditWebsite (id: string): void {
    this.$router.push({ name: 'My Website Edit', params: { id: id } })
  }

  private markingSeen = false
  onMarkedSeen (id: string): void {
    this.markingSeen = true
    Services.API.Admin.Businesses.MarkSeen(id)
      .then((result) => {
        console.log(result)
        if (this.newMembers === null) return
        var index = this.newMembers.findIndex(x => x.BusinessBranchId === id)
        this.newMembers.splice(index, 1)
      }).finally(() => {
        this.markingSeen = false
      })
  }
}

